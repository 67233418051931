import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@util/environment';
import { Observable } from 'rxjs';
import { UserOperationBankAccountDetails } from './interfaces/bank-account-details.interface';
import { UserOperationChangeCurrentPasswordRequest } from './interfaces/change-current-password.interface';
import { UserOperationChangePasswordRequest } from './interfaces/change-password.interface';
import { UserOperationEmailOtpVerification } from './interfaces/email-verification.interface';
import { UserOperationExistingUser } from './interfaces/existing-user.interface';
import { UserOperationMember } from './interfaces/member.interface';
import { PhonevalidationOtpVerification } from './interfaces/phone-validation-otp-verification.interface';
import { UserOperationUpdatePhoneNumberRequest } from './interfaces/update-phonenumber.interface';
import { UserOperationVerifyMemberRequest } from './interfaces/verify-member-request.interface';

@Injectable({
  providedIn: 'root',
})
export class UserOperationDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
  ) {}

  sendEmailOTP$(email: string): Observable<UserOperationMember> {
    return this.http.post<UserOperationMember>(`${this.env.environment.api.userOperation}/members/email`, { email });
  }

  verifyEmailOTP$(emailOtp: UserOperationEmailOtpVerification): Observable<UserOperationEmailOtpVerification> {
    return this.http.post<UserOperationEmailOtpVerification>(
      `${this.env.environment.api.userOperation}/members/email-verification`,
      emailOtp,
    );
  }

  updatePhoneNumber$(memberId: string, updateRequest: UserOperationUpdatePhoneNumberRequest): Observable<void> {
    return this.http.put<void>(`${this.env.environment.api.userOperation}/members/${memberId}/phone`, updateRequest);
  }

  updateEmailAddress$(memberId: string, updateRequest: UserOperationEmailOtpVerification): Observable<void> {
    return this.http.put<void>(`${this.env.environment.api.userOperation}/members/${memberId}/email`, updateRequest);
  }

  verifyMember$(verifyMemberDetails: UserOperationVerifyMemberRequest): Observable<void> {
    return this.http.post<void>(`${this.env.environment.api.userOperation}/members/verify`, verifyMemberDetails);
  }

  registerAsUser$(member: UserOperationMember): Observable<UserOperationMember> {
    return this.http.post<UserOperationMember>(`${this.env.environment.api.userOperation}/members/as-user`, member);
  }

  getMember$(username: string): Observable<UserOperationMember> {
    return this.http.get<UserOperationMember>(`${this.env.environment.api.userOperation}/members`, {
      params: { username },
    });
  }

  updateMember$(memberId: string, member: UserOperationMember): Observable<UserOperationMember> {
    return this.http.put<UserOperationMember>(`${this.env.environment.api.userOperation}/members/${memberId}`, member);
  }

  sendResetEmailOTP$(email: string): Observable<UserOperationExistingUser> {
    return this.http.post<UserOperationExistingUser>(
      `${this.env.environment.api.userOperation}/members/reset-password/email`,
      { email },
    );
  }

  changeCurrentPassword$(changeCurrentPasswordRequest: UserOperationChangeCurrentPasswordRequest): Observable<void> {
    return this.http.post<void>(
      `${this.env.environment.api.userOperation}/members/change-current-password`,
      changeCurrentPasswordRequest,
      { headers: { 'Accept-Language': this.env.environment.locale } },
    );
  }

  changePassword$(changePasswordRequest: UserOperationChangePasswordRequest): Observable<void> {
    return this.http.post<void>(
      `${this.env.environment.api.userOperation}/members/change-password`,
      changePasswordRequest,
      { headers: { 'Accept-Language': this.env.environment.locale } },
    );
  }

  getUserBankDetails$(memberId: string): Observable<UserOperationBankAccountDetails> {
    return this.http.get<UserOperationBankAccountDetails>(
      `${this.env.environment.api.userOperation}/members/${memberId}/bank`,
    );
  }

  addBankAccountDetails$(
    bankAccountDetails: UserOperationBankAccountDetails,
    memberId: string,
  ): Observable<UserOperationBankAccountDetails> {
    return this.http.post<UserOperationBankAccountDetails>(
      `${this.env.environment.api.userOperation}/members/${memberId}/bank`,
      bankAccountDetails,
    );
  }

  updateBankAccountDetails$(
    bankAccountDetails: UserOperationBankAccountDetails,
    memberId: string,
  ): Observable<UserOperationBankAccountDetails> {
    return this.http.put<UserOperationBankAccountDetails>(
      `${this.env.environment.api.userOperation}/members/${memberId}/bank`,
      bankAccountDetails,
    );
  }

  deleteBankAccountDetails$(memberId: string): Observable<void> {
    return this.http.delete<void>(`${this.env.environment.api.userOperation}/members/${memberId}/bank`);
  }

  sendPhoneOTP$(phoneNumber: string): Observable<void> {
    return this.http.post<void>(`${this.env.environment.api.userOperation}/phone`, { phoneNumber });
  }

  verifyPhoneOTP$({ phoneNumber, otp }: PhonevalidationOtpVerification): Observable<void> {
    return this.http.get<void>(`${this.env.environment.api.userOperation}/phone`, {
      params: {
        phoneNumber,
        verificationCode: otp,
      },
    });
  }
}
