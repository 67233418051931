import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIIconModule } from '../icon';
import { UIStepComponent } from './components/step/step.component';
import { UIStepperComponent } from './components/stepper/stepper.component';

@NgModule({
  declarations: [UIStepperComponent, UIStepComponent],
  imports: [CommonModule, UIIconModule],
  exports: [UIStepperComponent, UIStepComponent],
})
export class UIStepperModule {}
