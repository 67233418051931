export enum UIIcon {
  Activate = 'activate',
  AddMultiple = 'add-multiple',
  AddUser = 'add-user',
  Agencies = 'agencies',
  Agents = 'agents',
  Announcement = 'announcement',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowSublevel = 'arrow-sublevel',
  ArrowUp = 'arrow-up',
  Attachment = 'attachment',
  Baby = 'baby',
  Balance = 'balance',
  Bank = 'bank',
  Batch = 'batch',
  Bed = 'bed',
  Beneficiary = 'beneficiary',
  BenefitsOn = 'benefits-on',
  Benefits = 'benefits',
  Bin = 'bin',
  Book = 'book',
  Building = 'building',
  Cake = 'cake',
  Calendar = 'calendar',
  Camera = 'camera',
  CheckDouble = 'check-double',
  CheckSingle = 'check-single',
  ChecklistMixed = 'checklist-mixed',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  CircleAdd = 'circle-add',
  CircleMinus = 'circle-minus',
  CircleNo = 'circle-no',
  CircleWarning = 'circle-warning',
  CircleYes = 'circle-yes',
  Claims = 'claims',
  CloseFilled = 'close-filled',
  Coinsurance = 'coinsurance',
  Compensation = 'compensation',
  Complaints = 'complaints',
  Copy = 'copy',
  CoverRules = 'cover-rules',
  CPIcon = 'cp-icon',
  Deductible = 'deductible',
  Document = 'document',
  Dots = 'dots',
  Download = 'download',
  Drugs = 'drugs',
  Edit = 'edit',
  Ellipsis = 'ellipsis',
  Email = 'email',
  Family = 'family',
  Filter = 'filter',
  Fingerprint = 'fingerprint',
  Flag = 'flag',
  Folder = 'folder',
  GenderFemale = 'gender-female',
  GenderMale = 'gender-male',
  Glasses = 'glasses',
  Globe = 'globe',
  Gps = 'gps',
  HamburgerMenu = 'hamburger-menu',
  Handout = 'handout',
  HeartOn = 'heart-on',
  Heart = 'heart',
  Hide = 'hide',
  History = 'history',
  Hospital = 'hospital',
  Hourglass = 'hourglass',
  HouseOn = 'house-on',
  House = 'house',
  Inactive = 'inactive',
  Info = 'info',
  Invoices = 'invoices',
  Limit = 'limit',
  Link = 'link',
  Location = 'location',
  Locked = 'locked',
  MaritalStatus = 'marital-status',
  Minus = 'minus',
  Mobile = 'mobile',
  MtibaIcon = 'mtiba-icon',
  Network = 'network',
  NewTab = 'new-tab',
  NotificationOn = 'notification-on',
  Notification = 'notification',
  Participants = 'participants',
  Payers = 'payers',
  Pending = 'pending',
  Percentage = 'percentage',
  Phone = 'phone',
  Pin = 'pin',
  Plus = 'plus',
  Policy = 'policy',
  PreAuthorization = 'pre-authorization',
  Pregnant = 'pregnant',
  Pricelist = 'pricelist',
  Printer = 'printer',
  Privacy = 'privacy',
  Provider = 'provider',
  QuestionInfoOn = 'question-info-on',
  QuestionInfo = 'question-info',
  ReferTreatment = 'refer-treatment',
  Refresh = 'refresh',
  Reload = 'reload',
  Remove = 'remove',
  Reports = 'reports',
  Reset = 'reset',
  SearchOn = 'search-on',
  Search = 'search',
  ServiceDesk = 'service-desk',
  Settings = 'settings',
  ShopOn = 'shop-on',
  Shop = 'shop',
  Show = 'show',
  SignOut = 'sign-out',
  Sms = 'sms',
  Sort = 'sort',
  StarOn = 'star-on',
  Star = 'star',
  Stethoscope = 'stethoscope',
  SwapHorizontal = 'swap-horizontal',
  Symptom = 'symptom',
  Tooth = 'tooth',
  Transactions = 'transactions',
  Unlocked = 'unlocked',
  Upload = 'upload',
  UserManagement = 'user-management',
  UserOn = 'user-on',
  User = 'user',
  Users = 'users',
  Visits = 'visits',
  Warning = 'warning',
}
