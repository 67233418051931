import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIIconColor } from './icon-color.type';
import { UIIcon } from './icon.enum';

interface UIIconSize {
  width: string;
  height: string;
}

@Component({
  selector: 'ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIIconComponent {
  @Input() icon: `${UIIcon}` = UIIcon.Activate;
  @Input() description!: string;

  @Input() set sizeInPixels(size: number) {
    this.svgStyles = { width: `${size}px`, height: `${size}px` };
  }

  @Input() color: UIIconColor = 'granite';
  @Input() direction: 'up' | 'down' | 'left' | 'right' = 'up';

  get iconClass(): string {
    return [`ui-icon--color-${this.color}`, `ui-icon--direction-${this.direction}`].join(' ');
  }

  svgStyles: UIIconSize = { width: '24px', height: '24px' };
}
