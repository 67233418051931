<div class="cookies-overlay" *ngIf="(showOverlay$ | async) === true">
  <p>
    This site uses cookies to offer a great user experience. The information, collected in pseudonymized form only, is
    not used for any other purpose nor shared or combined with other data. By using this web site, you accept our use of
    cookies. For more information, please view our
    <button ui-inline-button (click)="openTermsAndConditions()">Terms and Conditions of Service</button> &amp;
    <button ui-inline-button (click)="openPrivacyStatement()">Privacy Statement</button>.
  </p>
  <h4 class="mt-4 mb-45">
    <button ui-inline-button (click)="manageCookies()">Manage cookies</button>
  </h4>
  <p class="mb-0 pt-2">
    <button ui-button (click)="close()">Close</button>
  </p>
</div>
