import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIButtonModule } from '../button';
import { UIIconModule } from '../icon';
import { UIBannerComponent } from './components/banner/banner.component';

@NgModule({
  declarations: [UIBannerComponent],
  imports: [CommonModule, UIIconModule, UIButtonModule],
  exports: [UIBannerComponent],
})
export class UIBannerModule {}
