import { HttpBackend, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Environment } from './environment.interface';
import { Info } from './info.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  environment: Environment = { locale: 'en-AE', branding: 'aspire' } as Environment;
  info: Info;

  constructor(private readonly http: HttpBackend) {}

  loadEnvironment$(): Observable<Environment> {
    return this.http
      .handle(new HttpRequest('GET', `./assets/environment/environment.json?v=${new Date().getTime()}`))
      .pipe(
        filter((event: HttpEvent<Environment>) => event.type === HttpEventType.Response),
        map((response: HttpEvent<Environment>) => (response as HttpResponse<Environment>).body as Environment),
        tap((e: Environment) => (this.environment = e)),
      );
  }

  loadInfo$(): Observable<Info> {
    return this.http.handle(new HttpRequest('GET', `./assets/info.json?v=${new Date().getTime()}`)).pipe(
      filter((event: HttpEvent<Info>) => event.type === HttpEventType.Response),
      map((response: HttpEvent<Info>) => (response as HttpResponse<Info>).body as Info),
      tap((e: Info) => (this.info = e)),
    );
  }
}
