import { HttpBackend, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@util/environment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CpUserResponse } from '../../interfaces/user-response.interface';
import { JWT, parseJwt } from '../../util/jwt.util';

@Injectable({
  providedIn: 'root',
})
export class AuthUsermanagementDataService {
  constructor(
    private readonly http: HttpBackend,
    private readonly env: EnvironmentService,
  ) {}

  login$(username: string, password: string): Observable<CpUserResponse> {
    return this.http
      .handle(
        new HttpRequest(
          'POST',
          `${this.env.environment.api.usermanagement}/login`,
          { username, password },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { headers: new HttpHeaders({ 'Accept-Version': '2' }) },
        ),
      )
      .pipe(
        filter((event: HttpEvent<CpUserResponse>) => event.type === HttpEventType.Response),
        map((response: HttpEvent<CpUserResponse>) => (response as HttpResponse<CpUserResponse>).body as CpUserResponse),
        map((response: CpUserResponse) => {
          const { sub, exp }: JWT = parseJwt(response.accessToken);
          return { ...response, externalKey: sub, expirationDate: exp };
        }),
      );
  }

  refresh$(): Observable<CpUserResponse> {
    return this.http.handle(new HttpRequest('POST', `${this.env.environment.api.usermanagement}/refresh`, {})).pipe(
      filter((event: HttpEvent<CpUserResponse>) => event.type === HttpEventType.Response),
      map((response: HttpEvent<CpUserResponse>) => (response as HttpResponse<CpUserResponse>).body as CpUserResponse),
      map((response: CpUserResponse) => {
        const { sub, exp }: JWT = parseJwt(response.accessToken);
        return { ...response, externalKey: sub, expirationDate: exp };
      }),
    );
  }
}
