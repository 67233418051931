import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UIDialogConfirmData } from './dialog-confirm-data.interface';
import { UIDialogConfirmComponent } from './dialog-confirm.component';

@Injectable()
export class UIDialogConfirmService {
  constructor(private readonly dialog: MatDialog) {}

  open$(data: UIDialogConfirmData): Observable<boolean> {
    return this.dialog.open(UIDialogConfirmComponent, { maxWidth: 'calc(100vw - 2rem)', data }).afterClosed();
  }
}
