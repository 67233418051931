import { Injectable } from '@angular/core';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

@Injectable()
export class UIPhoneNumberService {
  phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  allowedNumberTypes: Array<PhoneNumberType> = [PhoneNumberType.MOBILE, PhoneNumberType.PERSONAL_NUMBER];

  convertToPhoneNumber(phoneValue: string): string | null {
    if (!phoneValue || phoneValue.length <= 5) {
      return null;
    }

    const phoneNumber: PhoneNumber = this.parseToPhoneNumber(phoneValue);

    return this.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164).substring(1);
  }

  extractNationalNumber(phoneValue: string): string {
    if (!phoneValue || phoneValue.length <= 5) {
      return null;
    }
    const phoneNumber: PhoneNumber = this.parseToPhoneNumber(phoneValue);

    return phoneNumber.getNationalNumber().toString();
  }

  extractCountryCode(phoneValue: string): string {
    if (!phoneValue || phoneValue.length <= 5) {
      return null;
    }
    const phoneNumber: PhoneNumber = this.parseToPhoneNumber(phoneValue);

    return this.formatPhoneValuePrefix(phoneNumber.getCountryCode().toString());
  }

  isValid(phoneValue: string): boolean {
    const phoneNumber: PhoneNumber = this.parseToPhoneNumber(phoneValue);
    return this.phoneNumberUtil.isValidNumber(phoneNumber);
  }

  isAllowedNumber(phoneValue: string): boolean {
    const phoneNumber: PhoneNumber = this.parseToPhoneNumber(phoneValue);
    const numberType: PhoneNumberType = this.phoneNumberUtil.getNumberType(phoneNumber);

    return this.allowedNumberTypes.includes(numberType);
  }

  private parseToPhoneNumber(phoneValue: string): PhoneNumber {
    if (!phoneValue || phoneValue.length <= 5) {
      return null;
    }
    const phoneNumber: string = this.formatPhoneValuePrefix(phoneValue);

    return this.phoneNumberUtil.parse(phoneNumber);
  }

  private formatPhoneValuePrefix(phoneValue: string): string {
    if (!phoneValue) {
      return null;
    }
    return this.isNumber(phoneValue?.[0]) && phoneValue?.[0] !== '0' ? '+' + phoneValue : phoneValue;
  }

  private isNumber(value: string): boolean {
    return /\d/.test(value);
  }
}
