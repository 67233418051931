import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIIcon } from '../../../icon/icon.enum';

@Component({
  selector: 'button[ui-bottom-navigation-item]',
  templateUrl: './bottom-navigation-item.component.html',
  styleUrls: ['./bottom-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIBottomNavigationItemComponent {
  @Input() icon!: `${UIIcon}`;
  @Input() iconActive!: `${UIIcon}`;
}
