import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { UIStepComponent } from '../step/step.component';

@Component({
  selector: 'ui-stepper',
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UIStepperComponent {
  currentStep: number = 0;

  @ContentChildren(UIStepComponent)
  readonly steps: QueryList<UIStepComponent>;

  nextStep(): void {
    this.currentStep++;
  }

  previousStep(): void {
    this.currentStep--;
  }
}
