import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Capacitor } from '@capacitor/core';
import { distinctUntilChanged, filter, ReplaySubject } from 'rxjs';
import { AnalyticalCookiesConsent } from './analytical-cookies-consent.enum';
import { CookiesDialogComponent } from './components/cookies-dialog/cookies-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  private readonly analyticalCookiesConsentSubject$: ReplaySubject<AnalyticalCookiesConsent> =
    new ReplaySubject<AnalyticalCookiesConsent>();
  readonly analyticalCookiesConsent$ = this.analyticalCookiesConsentSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  private readonly showOverlaySubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  readonly showOverlay$ = this.showOverlaySubject$.asObservable();

  constructor(private readonly dialog: MatDialog) {}

  init(): void {
    if (!this.requireAnalyticalCookiesConsent()) {
      this.setLocalStorageConsent(AnalyticalCookiesConsent.ACCEPTED);
    }

    this.analyticalCookiesConsentSubject$.next(this.getLocalStorageConsent());
  }

  openOverlayIfRequired(): void {
    if (this.getLocalStorageConsent() === AnalyticalCookiesConsent.UNKNOWN) {
      this.openOverlay();
    }
  }

  closeOverlay(): void {
    // If it was still UNKNOWN while closing the dialog, no change was made by the user, so update preference to ACCEPTED
    if (this.getLocalStorageConsent() === AnalyticalCookiesConsent.UNKNOWN) {
      this.acceptAnalyticalCookies();
    }

    this.showOverlaySubject$.next(false);
  }

  openManageCookiesDialog(): void {
    this.dialog
      .open(CookiesDialogComponent, {
        maxWidth: 'calc(min(35rem, 100vw - 2rem))',
        data: { consent: this.getLocalStorageConsent() },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((consent: AnalyticalCookiesConsent) => {
        // The user chose to accept cookies
        if (consent === AnalyticalCookiesConsent.ACCEPTED) {
          this.acceptAnalyticalCookies();

          // The user rejected cookies, but previously accepted them. Requires reload to stop analytical tracking.
        } else if (
          consent === AnalyticalCookiesConsent.REJECTED &&
          this.getLocalStorageConsent() === AnalyticalCookiesConsent.ACCEPTED
        ) {
          this.rejectAnalyticalCookies();
          window.location.reload();

          // The user rejected cookies
        } else if (consent === AnalyticalCookiesConsent.REJECTED) {
          this.rejectAnalyticalCookies();
        }
      });
  }

  // Only require cookies consent on web, when not native
  requireAnalyticalCookiesConsent(): boolean {
    return !Capacitor.isNativePlatform();
  }

  private acceptAnalyticalCookies(): void {
    this.setLocalStorageConsent(AnalyticalCookiesConsent.ACCEPTED);
    this.analyticalCookiesConsentSubject$.next(AnalyticalCookiesConsent.ACCEPTED);
  }

  private rejectAnalyticalCookies(): void {
    this.setLocalStorageConsent(AnalyticalCookiesConsent.REJECTED);
    this.analyticalCookiesConsentSubject$.next(AnalyticalCookiesConsent.REJECTED);
  }

  private openOverlay(): void {
    this.showOverlaySubject$.next(true);
  }

  private getLocalStorageConsent(): AnalyticalCookiesConsent {
    switch (localStorage.getItem('acceptAnalyticalCookies')) {
      case AnalyticalCookiesConsent.ACCEPTED:
        return AnalyticalCookiesConsent.ACCEPTED;
      case AnalyticalCookiesConsent.REJECTED:
        return AnalyticalCookiesConsent.REJECTED;
      default:
        return AnalyticalCookiesConsent.UNKNOWN;
    }
  }

  private setLocalStorageConsent(consent: AnalyticalCookiesConsent): void {
    localStorage.setItem('acceptAnalyticalCookies', consent);
  }
}
