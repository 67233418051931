import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export abstract class UIControlValueAccessorBase<T> implements ControlValueAccessor {
  protected onChangeFn!: (newValue: T) => void;
  protected onTouchedFn!: () => void;

  abstract writeValue(newValue: T): void;

  registerOnChange(onChangeHook: (newValue: T) => void): void {
    this.onChangeFn = onChangeHook;
  }

  registerOnTouched(onTouchedHook: () => void): void {
    this.onTouchedFn = onTouchedHook;
  }
}
