import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, take } from 'rxjs';
import { UISnackbarStateService } from './snackbar-state.service';
import { UISnackbarType } from './snackbar-type.enum';
import { UISnackbarComponent } from './snackbar.component';
import { UISnackbar } from './snackbar.interface';

@Injectable({
  providedIn: 'root',
})
export class UISnackbarService {
  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly snackbarStateService: UISnackbarStateService,
  ) {}

  showNeutral(message: string, translate: boolean = false): void {
    this.showSnackbar(message, UISnackbarType.Neutral, translate);
  }
  showPositive(message: string, translate: boolean = false): void {
    this.showSnackbar(message, UISnackbarType.Positive, translate);
  }

  showNegative(message: string, translate: boolean = false): void {
    this.showSnackbar(message, UISnackbarType.Negative, translate);
  }

  showWarning(message: string, translate: boolean = false): void {
    this.showSnackbar(message, UISnackbarType.Warning, translate);
  }

  private showSnackbar(message: string, type: UISnackbarType, translate: boolean): void {
    if (translate) {
      message = this.translateService.instant(message);
    }

    const snackbar: UISnackbar = { message, type };
    this.snackbarStateService.add(snackbar);
    this.showNext();
  }

  private showNext(): void {
    if (this.snackbarStateService.showing || !this.snackbarStateService.hasNext()) {
      return;
    }

    this.snackbarStateService.showing = true;
    const snackbar: UISnackbar = this.snackbarStateService.next();

    this.openCpSnackbarAfterDismissed$(snackbar)
      .pipe(take(1))
      .subscribe(() => {
        this.snackbarStateService.showing = false;
        this.showNext();
      });
  }

  private openCpSnackbarAfterDismissed$(snackbar: UISnackbar): Observable<MatSnackBarDismiss> {
    const openedSnackbar: MatSnackBarRef<UISnackbarComponent> = this.snackbar.openFromComponent<
      UISnackbarComponent,
      UISnackbar
    >(UISnackbarComponent, {
      data: snackbar,
    });

    return openedSnackbar.afterDismissed();
  }
}
