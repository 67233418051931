<ui-dialog>
  <ng-container title>{{ title }}</ng-container>
  <ng-container description>{{ description }}</ng-container>
  <button ui-button type="primary" data-cy="confirm" primary-action (click)="confirmButton()">
    {{ confirmButtonText }}
  </button>
  <button ui-button type="secondary" data-cy="cancel" (click)="cancelButton()" secondary-action>
    {{ cancelButtonText }}
  </button>
</ui-dialog>
