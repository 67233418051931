import { Component, Input } from '@angular/core';
import { UIControlValueAccessorBase, uiValueAccessorProvider } from '../../../form';

@Component({
  selector: 'ui-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [uiValueAccessorProvider(UIToggleComponent)],
})
export class UIToggleComponent extends UIControlValueAccessorBase<boolean> {
  toggled: boolean = false;
  @Input() disabled: boolean = false;

  override writeValue(toggled: boolean): void {
    this.toggled = toggled;
  }

  toggle(): void {
    this.writeValue(!this.toggled);
    if (this.onChangeFn) {
      this.onChangeFn(this.toggled);
    }
  }
}
