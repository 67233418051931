export interface JWT {
  sub: string;
  exp: number;
  roles: string[];
}

// This is not necessary IF usermanagement returns the externalKey and/or the expiryDate of the token as a date instead of a ttl.
// Thanks stackoverflow: https://stackoverflow.com/a/38552302/6256317
export function parseJwt(token: string): JWT {
  const base64Url: string = token.split('.')[1];
  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload: string = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((payload: string) => '%' + ('00' + payload.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return JSON.parse(jsonPayload);
}
