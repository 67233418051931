import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIIconModule } from '../icon';
import { UICheckboxComponent } from './components/checkbox/checkbox.component';

@NgModule({
  imports: [CommonModule, UIIconModule, ReactiveFormsModule, FormsModule],
  declarations: [UICheckboxComponent],
  exports: [UICheckboxComponent],
})
export class UICheckboxModule {}
