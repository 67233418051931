<form [formGroup]="filesForm" (ngSubmit)="onSubmit()" *ngIf="filesForm">
  <input
    #file
    hidden
    type="file"
    (change)="onFileSelected(file.files)"
    multiple
    accept="application/pdf,image/webp,image/jpeg,image/jpg,image/png"
  />

  <div class="pb-3 mt-4">
    <div class="d-flex justify-content-center align-items-center file-upload pt-3 pb-3 mb-3" (click)="file.click()">
      <ui-icon icon="upload" class="me-1"></ui-icon>
      <div class="file-upload__label ms-1">Add a file</div>
    </div>

    <p class="assistive-text mb-0">
      Supports pdf, doc(x), excel(x), png, jpeg, webp.
      <br />
      Max file size is 10 MB.
    </p>
  </div>

  <div class="pb-3" *ngFor="let file of filesToBigForUpload; index as index">
    <ui-file-item [file]="file" [tooLarge]="true" (clickEvent)="onDeleteFileToBigForUpload(index)"> </ui-file-item>
  </div>

  <div class="pb-3" *ngFor="let file of filesForUpload; index as index">
    <ui-file-item [file]="file" (clickEvent)="onDeleteFile(index)"> </ui-file-item>

    <ui-form-field>
      <select [formControl]="fileTypesControl.at(index)" [name]="'file-' + index">
        <option value="" disabled selected>Select file type</option>
        <option *ngFor="let fileType of fileTypes" [value]="fileType.value">{{ fileType.label }}</option>
      </select>
      <ui-form-field-error *ngIf="hasFileTypeError(index)">Please select a file type</ui-form-field-error>
    </ui-form-field>
  </div>

  <div *ngIf="hasNoFileUploadedError" class="validation d-flex align-items-center">
    <ui-icon icon="warning" color="red" class="me-2"></ui-icon>
    Please upload
    <ng-container *ngIf="necessaryFilesTypes.length === 0">a file.</ng-container>
    <ng-container *ngFor="let necessaryFilesType of necessaryFilesTypes; let last = last">
      {{
        last && necessaryFilesTypes.length > 1
          ? 'and ' + necessaryFilesType.label + '.'
          : last
          ? necessaryFilesType.label + '.'
          : necessaryFilesType.label + ', '
      }}
    </ng-container>
  </div>
  <div *ngIf="hasMissingFileError" class="validation d-flex align-items-center">
    <ui-icon icon="warning" color="red" class="me-2"></ui-icon>
    <ng-container *ngFor="let necessaryFilesType of necessaryFilesTypes; let last = last">
      {{
        last && necessaryFilesTypes.length > 1
          ? 'and ' + necessaryFilesType.label
          : last
          ? necessaryFilesType.label
          : necessaryFilesType.label + ', '
      }}
    </ng-container>
    <ng-container> {{ necessaryFilesTypes.length > 1 ? ' are' : ' is' }} required. </ng-container>
  </div>
  <div *ngIf="hasExceedsRequiredAmountError" class="validation d-flex align-items-center">
    <ui-icon icon="warning" color="red" class="me-2"></ui-icon>
    <div>
      Please select only
      <ng-container *ngFor="let necessaryFilesType of necessaryFilesTypes; let last = last">
        {{
          last && necessaryFilesTypes.length > 1
            ? 'and ' + necessaryFilesType.value.amount + ' of ' + necessaryFilesType.label + '.'
            : last
            ? necessaryFilesType.value.amount + ' of ' + necessaryFilesType.label + '.'
            : necessaryFilesType.value.amount + ' of ' + necessaryFilesType.label + ', '
        }}
      </ng-container>
    </div>
  </div>
</form>
