import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EnvironmentService } from '@util/environment';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { GraphqlService } from './graphql.service';
import { provideApollo } from './provide-apollo';

@NgModule({
  imports: [CommonModule, HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo,
      deps: [HttpLink, GraphqlService, EnvironmentService],
    },
  ],
})
export class DataAccessApolloModule {}
