import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uiFileSize',
})
export class UIFileSizePipe implements PipeTransform {
  // Mac finder displays with steps of 1000 not 2^10 (1024)
  transform(sizeInBytes: number): string {
    const sizeInMb: number = sizeInBytes / 1000 / 1000;
    if (sizeInMb > 0.5) {
      return `${(Math.round(sizeInMb * 10) / 10).toFixed(1)} MB`;
    }
    const sizeInKb: number = sizeInBytes / 1000;
    return `${(Math.round(sizeInKb * 10) / 10).toFixed(1)} KB`;
  }
}
