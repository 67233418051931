import { Injectable } from '@angular/core';
import { datadogRum, RumEvent } from '@datadog/browser-rum';
import { EnvironmentService } from '@util/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorTrackingService {
  constructor(private readonly env: EnvironmentService) {}

  init(): void {
    if (window.location.host.startsWith('localhost')) {
      return;
    }

    datadogRum.init({
      applicationId: this.env.environment.datadog.applicationId,
      clientToken: this.env.environment.datadog.clientId,
      site: this.env.environment.datadog.site,
      service: 'health-hub',
      env: this.env.environment.country + '-' + this.env.environment.stage,
      version: this.env.info.version,
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,

      beforeSend: (event: RumEvent) => {
        // Since we are using a hash URL, replace this with a forward slash
        event.view.url = event.view.url.replace(/\/#\//, '/');
      },
    });
  }
}
