import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiError } from '../models/error.error';
import { ErrorFormattingService } from './error-formatting.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private readonly apiErrorFormattingService: ErrorFormattingService) {}

  handleError(error: any): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      const message: string = this.apiErrorFormattingService.formatApiError(error);
      return throwError(() => new ApiError(message, error));
    }
    return throwError(error);
  }
}
