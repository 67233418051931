import { AbstractControl, FormArray, FormControl, ValidationErrors } from '@angular/forms';
import { UINecessaryFileType } from '../interfaces/necessary-file-type.interface';

export const uiMissingFilesValidation: <FileType>(
  requiredFiles: Array<UINecessaryFileType<FileType>>,
  formArrayName: string,
) => (control: AbstractControl) => ValidationErrors | null =
  <FileType>(requiredFiles: Array<UINecessaryFileType<FileType>>, formArrayName: string) =>
  (control: AbstractControl): ValidationErrors | null => {
    const fileTypes: FormArray<FormControl<FileType | string | null>> = control.get(formArrayName) as FormArray;

    const selectedFileTypes: Array<FileType | string | null> = fileTypes.controls.map(
      (c: FormControl<FileType | string | null>) => c.value,
    );

    if (selectedFileTypes.length === 0) {
      return { noFilesUploaded: true };
    }
    if (isExceedingRequiredAmount<FileType>(requiredFiles, selectedFileTypes)) {
      return { exceedsRequiredAmount: true };
    }
    if (requiredFiles.length !== 0 && hasMissingFileType<FileType>(requiredFiles, selectedFileTypes)) {
      return { missingFileType: true };
    }

    return null;
  };

function isExceedingRequiredAmount<FileType>(
  requiredFiles: Array<UINecessaryFileType<FileType>>,
  selectedFileTypes: Array<FileType | string | null>,
): boolean {
  return requiredFiles.some(
    (value: UINecessaryFileType<FileType>) =>
      selectedFileTypes.filter((selectedFileType: FileType | string | null) => selectedFileType === value.type).length >
      value.amount,
  );
}

function hasMissingFileType<FileType>(
  requiredFiles: Array<UINecessaryFileType<FileType>>,
  selectedFileTypes: Array<FileType | string | null>,
): boolean {
  return !requiredFiles.some((value: UINecessaryFileType<FileType>) => selectedFileTypes.includes(value.type));
}
