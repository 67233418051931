import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIButtonModule } from '../../button';
import { UIDialogModule } from '../dialog/dialog.module';
import { UIDialogConfirmComponent } from './dialog-confirm.component';
import { UIDialogConfirmService } from './dialog-confirm.service';

@NgModule({
  declarations: [UIDialogConfirmComponent],
  exports: [UIDialogConfirmComponent],
  imports: [CommonModule, UIDialogModule, UIButtonModule],
  providers: [UIDialogConfirmService],
})
export class UIDialogConfirmModule {}
