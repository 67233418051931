import { AbstractControl, ValidatorFn } from '@angular/forms';
import { UI_INVALID_PHONE_NUMBER_ERROR } from '../constants/invalid-phone-number-error.constant';
import { UIPhoneNumberService } from '../services/phone-number.service';

const phonevalidationService: UIPhoneNumberService = new UIPhoneNumberService();

export function uiPhoneNumberValidator(countryCode: string, nationalNumber: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const countryCodeControl: AbstractControl | null = controls.get(countryCode);
    const nationalNumberControl: AbstractControl | null = controls.get(nationalNumber);

    if (!nationalNumberControl?.value || !countryCodeControl?.value) {
      return null;
    }

    const phoneValue: string = countryCodeControl.value + nationalNumberControl.value;

    try {
      if (!phonevalidationService.isValid(phoneValue) || !phonevalidationService.isAllowedNumber(phoneValue)) {
        return { [UI_INVALID_PHONE_NUMBER_ERROR]: true };
      }
      return null;
    } catch (e) {
      return { [UI_INVALID_PHONE_NUMBER_ERROR]: true };
    }
  };
}
