import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIActionButtonComponent } from './components/action-button/action-button.component';
import { UIButtonComponent } from './components/button/button.component';
import { UIInlineButtonComponent } from './components/inline-button/inline-button.component';

@NgModule({
  declarations: [UIButtonComponent, UIInlineButtonComponent, UIActionButtonComponent],
  imports: [CommonModule],
  exports: [UIButtonComponent, UIInlineButtonComponent, UIActionButtonComponent],
})
export class UIButtonModule {}
