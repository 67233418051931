export const CUSTOM_FIELD_APP_VERSION: string = 'cf_app_version';
export const CUSTOM_FIELD_APP_ENVIRONMENT: string = 'cf_app_environment';
export const CUSTOM_FIELD_USER_AGENT: string = 'cf_useragent';
export const CUSTOM_FIELD_USER_KEY: string = 'cf_userkey';
export const CUSTOM_FIELD_PHONE_NUMBER: string = 'cf_phone_number';
export const CUSTOM_FIELD_REASON: string = 'cf_reason';
export const CUSTOM_FIELD_NATIONAL_ID: string = 'cf_national_id';
export const CUSTOM_FIELD_POLICY_NUMBER: string = 'cf_policy_number';

export const REASONS_TO_HIDE_FOR_UNAUTHENTICATED_USERS: string[] = [
  'Request for change in personal/family details',
  'Request for renewal of policy',
  'Request termination of a policy',
  'Complaint',
];
