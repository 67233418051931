import { ErrorHandler, Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client';
import { ApiError } from '@data-access/api/error';
import { datadogRum } from '@datadog/browser-rum';
import { UISnackbarService } from '@ui/components';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private readonly snackbarService: UISnackbarService) {
    super();
  }

  override handleError(error: unknown): void {
    if (ApiError.isInstance(error)) {
      this.snackbarService.showNegative((error as ApiError).message, true);
    }

    if (error instanceof ApolloError) {
      // Todo: translation --> { params: error.extraInfo?.params }
      this.snackbarService.showNegative(error.message, true);
    }

    // rethrowing the error using `throw error` cán cause unexpected behaviour such as errors seeming to be swallowed.
    // This might be due to the fact that Angular overwrites the console object in their ErrorHandler implementation.
    // for this reason we opt to log the error, so it is still visible in the console, ánd is Caught so the FE doesn't
    // crash.

    // eslint-disable-next-line no-console
    console.error(error);

    // As the error is Caught, we need to log it as a `source` error in DataDog to show up in Error Tracking.
    datadogRum.addError(error);
  }
}
