import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrandingAssetSrcDirective } from './branding-asset-src.directive';

@NgModule({
  declarations: [BrandingAssetSrcDirective],
  imports: [CommonModule],
  exports: [BrandingAssetSrcDirective],
})
export class BrandingModule {}
