import { MatDateFormats } from '@angular/material/core';
// Format that the backend expects
export const DATE_REQUEST_FORMAT: string = 'yyyy-MM-dd';
export const DATE_TIME_REQUEST_FORMAT: string = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const DATE_PREVIEW_FORMAT: string = 'dd-MM-yyyy';
export const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: DATE_PREVIEW_FORMAT,
  },
  display: {
    dateInput: DATE_PREVIEW_FORMAT,
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};
// min/max age rules (Should be moved in the future per environment?)
export const MIN_AGE: number = 18;
export const MAX_AGE: number = 110;
