import { Injectable } from '@angular/core';
import { UISnackbar } from './snackbar.interface';

@Injectable({ providedIn: 'root' })
export class UISnackbarStateService {
  private snackbarQueue: UISnackbar[] = [];
  showing = false;

  hasNext(): boolean {
    return !!this.snackbarQueue.length;
  }

  next(): UISnackbar {
    return this.snackbarQueue.shift();
  }

  add(snackbar: UISnackbar): void {
    this.snackbarQueue = [...this.snackbarQueue, snackbar];
  }
}
