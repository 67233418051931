import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIIconColor } from '../../../icon/icon-color.type';
import { UIIcon } from '../../../icon/icon.enum';

@Component({
  selector: 'ui-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIBannerComponent {
  @Input() color: 'neutral' | 'error' | 'success' | 'warning' | 'info' | 'highlight' = 'neutral';
  @Input() textAlign: 'left' | 'center' = 'left';
  @Input() fullWidth: boolean = false;
  @Input() icon!: `${UIIcon}`;

  get iconColor(): UIIconColor {
    const iconColors: { [key: string]: UIIconColor } = {
      neutral: 'french-azure',
      error: 'red',
      success: 'green',
      warning: 'orange',
      info: 'charcoal',
      highlight: 'grape',
    };
    return iconColors[this.color];
  }
}
