<div
  class="banner"
  [ngClass]="'banner--' + color"
  [class.banner--text-align-center]="textAlign === 'center'"
  [class.banner--with-buttons]="buttonPrimary.children.length + buttonSecondary.children.length"
  [class.banner--full-width]="fullWidth"
>
  <div class="banner__message">
    <div class="banner-icon" *ngIf="icon">
      <ui-icon [icon]="icon" [color]="iconColor" [sizeInPixels]="32"></ui-icon>
    </div>
    <div class="banner__text">
      <div class="banner-header">
        <ng-content select="[ui-banner-header]"></ng-content>
      </div>
      <div class="banner-content">
        <ng-content select="[ui-banner-content]"></ng-content>
      </div>
    </div>
  </div>
  <div class="banner__buttons">
    <div class="banner-button" #buttonPrimary>
      <ng-content select="[ui-banner-button-primary]"></ng-content>
    </div>
    <div class="banner-button" #buttonSecondary>
      <ng-content select="[ui-banner-button-secondary]"></ng-content>
    </div>
  </div>
</div>
