import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UIDialogComponent } from '../dialog/dialog.component';
import { UIDialogService } from './dialog.service';

@NgModule({
  declarations: [UIDialogComponent],
  exports: [UIDialogComponent],
  imports: [CommonModule, MatDialogModule],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    UIDialogService,
  ],
})
export class UIDialogModule {}
