import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIButtonModule } from '../button';
import { UIIconModule } from '../icon';
import { UISpinnerModule } from '../spinner';
import { UISnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [CommonModule, UIIconModule, UIButtonModule, UISpinnerModule],
  declarations: [UISnackbarComponent],
  exports: [UISnackbarComponent],
})
export class UISnackbarModule {}
