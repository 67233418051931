import { Injectable } from '@angular/core';
import { AuthService } from '@feature/auth';
import { NavController } from '@ionic/angular';
import { UISnackbarService } from '@ui/components';
import { GraphQLError } from 'graphql';
import { Observable } from 'rxjs';
import { GraphqlErrorClassification } from './graphql-error-classification.enum';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(
    private readonly authService: AuthService,
    private readonly snackbar: UISnackbarService,
    private readonly navCtrl: NavController,
  ) {}

  token$(): Observable<string | null> {
    return this.authService.getToken$();
  }

  shouldLogout(error: GraphQLError): boolean {
    return error.extensions && error.extensions['classification'] === GraphqlErrorClassification.UNAUTHORIZED;
  }

  logout(): void {
    this.snackbar.showNeutral('Your session has expired, please log in again');
    this.authService.logout();
    this.navCtrl.navigateForward(['/']);
  }
}
