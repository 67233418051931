import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrandingModule } from '@feature/branding';
import { UIIconModule } from '../icon/icon.module';
import { UIAppBarButtonComponent } from './app-bar-button/app-bar-button.component';
import { UIAppBarComponent } from './app-bar/app-bar.component';

@NgModule({
  declarations: [UIAppBarComponent, UIAppBarButtonComponent],
  imports: [CommonModule, UIIconModule, BrandingModule],
  exports: [UIAppBarComponent, UIAppBarButtonComponent],
})
export class UIAppBarModule {}
