/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Renderer2 } from '@angular/core';
import { UserOperationMember } from '@data-access/api/user-operation';
import { EnvironmentService } from '@util/environment';
import { BehaviorSubject, Observable, filter, take } from 'rxjs';
import {
  CUSTOM_FIELD_APP_ENVIRONMENT,
  CUSTOM_FIELD_APP_VERSION,
  CUSTOM_FIELD_NATIONAL_ID,
  CUSTOM_FIELD_PHONE_NUMBER,
  CUSTOM_FIELD_POLICY_NUMBER,
  CUSTOM_FIELD_REASON,
  CUSTOM_FIELD_USER_AGENT,
  CUSTOM_FIELD_USER_KEY,
  REASONS_TO_HIDE_FOR_UNAUTHENTICATED_USERS,
} from './freshdesk.constants';
import { FreshworksWidgetOptions } from './freshworks-widget-options.type';

@Injectable({
  providedIn: 'root',
})
export class FreshdeskService {
  private member: UserOperationMember | null;
  private policyNumbers: string[];

  private readonly initialisedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly initialised$: Observable<boolean> = this.initialisedSubject$.asObservable();

  constructor(private readonly env: EnvironmentService) {}

  /*
   * Getter for the widget api: https://developers.freshdesk.com/widget-api/
   */
  get freshworksWidget(): (...options: FreshworksWidgetOptions) => void {
    return (window as any).FreshworksWidget;
  }

  setMember(member: UserOperationMember | null): void {
    this.initialised$.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.member = member;
      this.reboot();
    });
  }

  setPolicyNumbers(policyNumbers: string[]): void {
    this.policyNumbers = policyNumbers;
  }

  openFAQ(): void {
    this.open(true);
  }

  openCustomerCare(): void {
    this.open();
  }

  initFreshdeskScripts(renderer: Renderer2, doc: Document): void {
    const freshworksURL: string = this.env.environment.freshworksURL;
    const widgetId: number = this.env.environment.freshdeskWidgetId;

    // eslint-disable-next-line @typescript-eslint/typedef
    const scriptBody = renderer.createElement('script');
    scriptBody.type = 'text/javascript';
    scriptBody.innerHTML = `window.fwSettings = {
      widget_id: ${widgetId},
    };
    !(function () {
      if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
    FreshworksWidget('hide', 'launcher');`;
    renderer.appendChild(doc.body, scriptBody);

    // eslint-disable-next-line @typescript-eslint/typedef
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = `${freshworksURL}/${widgetId}.js`;
    renderer.appendChild(doc.body, script);

    this.initialisedSubject$.next(true);
  }

  /** Opens the FreshDesk widget with pre-filled fields */
  private open(openFAQ: boolean = false): void {
    this.initialised$.pipe(filter(Boolean), take(1)).subscribe(() => {
      if (openFAQ) {
        this.freshworksWidget('open');
      } else {
        this.freshworksWidget('open', 'ticketForm', {
          formId: this.env.environment.freshdeskFormId,
        });
      }
      this.initFields();
    });
  }

  /**
   * Reboot the FreshDesk widget to empty fields and show all options in `Reasons` dropdown again.
   * The widget API doesn't allow us to re-show dropdown options again after they were hidden.
   */
  private reboot(): void {
    this.freshworksWidget('destroy');
    this.freshworksWidget('boot');
    this.freshworksWidget('hide', 'launcher');
  }

  private initFields(): void {
    this.populateDebugFields();
    if (this.member) {
      this.populateAuthenticatedFields();
    } else {
      this.populateUnauthenticatedFields();
    }

    this.freshworksWidget('hide', 'ticketForm', [`custom_fields.${CUSTOM_FIELD_USER_KEY}`]);
    this.freshworksWidget('hide', 'ticketForm', [`custom_fields.${CUSTOM_FIELD_APP_VERSION}`]);
    this.freshworksWidget('hide', 'ticketForm', [`custom_fields.${CUSTOM_FIELD_APP_ENVIRONMENT}`]);
    this.freshworksWidget('hide', 'ticketForm', [`custom_fields.${CUSTOM_FIELD_USER_AGENT}`]);
    this.freshworksWidget('hide', 'ticketForm', [`custom_fields.${CUSTOM_FIELD_NATIONAL_ID}`]);
    this.freshworksWidget('hide', 'ticketForm', [`custom_fields.${CUSTOM_FIELD_POLICY_NUMBER}`]);
  }

  private populateAuthenticatedFields(): void {
    // Use only the first policy number if available
    const policyNumber: string = this.policyNumbers && this.policyNumbers.length > 0 ? this.policyNumbers[0] : '';

    this.freshworksWidget(
      'identify',
      'ticketForm',
      {
        name: this.member.kyc.firstName + ' ' + this.member.kyc.lastName,
        email: this.member.kyc.email,
        custom_fields: {
          [CUSTOM_FIELD_PHONE_NUMBER]: this.member.kyc.phoneNumber,
          [CUSTOM_FIELD_USER_KEY]: this.member.id,
          [CUSTOM_FIELD_NATIONAL_ID]: this.member.kyc.idDocumentNumber,
          [CUSTOM_FIELD_POLICY_NUMBER]: policyNumber,
        },
      },
      {
        formId: this.env.environment.freshdeskFormId,
      },
    );
  }

  private populateUnauthenticatedFields(): void {
    this.freshworksWidget('hideChoices', 'ticketForm', {
      custom_fields: {
        [CUSTOM_FIELD_REASON]: REASONS_TO_HIDE_FOR_UNAUTHENTICATED_USERS,
      },
    });
  }

  private populateDebugFields(): void {
    const version: string = this.env.info.version;

    this.freshworksWidget(
      'identify',
      'ticketForm',
      {
        custom_fields: {
          [CUSTOM_FIELD_APP_VERSION]: version,
          [CUSTOM_FIELD_APP_ENVIRONMENT]: this.env.environment.country + '-' + this.env.environment.stage,
          [CUSTOM_FIELD_USER_AGENT]: navigator.userAgent,
        },
      },
      {
        formId: this.env.environment.freshdeskFormId,
      },
    );
  }
}
