<ui-icon *ngIf="snackbar.icon" class="me-2" [icon]="snackbar.icon" [color]="snackbar.color ?? 'primary'"></ui-icon>

<span matSnackBarLabel data-cy="snackbar-message"> {{ data.message }} </span>

<span matSnackBarActions class="ms-auto text-break" *ngIf="snackbar.actionText">
  <button
    ui-button
    type="text"
    [color]="snackbar.color"
    matSnackBarAction
    class="link-button"
    (click)="snackBarRef.dismissWithAction()"
  >
    {{ snackbar.actionText }}
  </button>
</span>
