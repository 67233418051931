import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { UIButtonModule } from '../button';
import { UIIconModule } from '../icon';
import { UIChipListComponent } from './components/chip-list/chip-list.component';
import { UIChipComponent } from './components/chip/chip.component';
@NgModule({
  declarations: [UIChipComponent, UIChipListComponent],
  imports: [CommonModule, MatChipsModule, UIIconModule, UIButtonModule],
  exports: [UIChipComponent, UIChipListComponent],
})
export class UIChipModule {}
