import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UICurrencyPipe } from './currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [UICurrencyPipe],
  exports: [UICurrencyPipe],
})
export class UICurrencyModule {}
