import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticalCookiesConsent } from '../../analytical-cookies-consent.enum';

@Component({
  selector: 'feature-cookies-dialog',
  templateUrl: './cookies-dialog.component.html',
  styleUrls: ['./cookies-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesDialogComponent implements OnInit {
  analyticalCookies: boolean = false;
  necessaryCookies: boolean = true;

  constructor(
    private readonly dialogRef: MatDialogRef<CookiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { consent: AnalyticalCookiesConsent },
  ) {}

  ngOnInit(): void {
    if (this.data.consent === AnalyticalCookiesConsent.REJECTED) {
      this.analyticalCookies = false;
    } else {
      this.analyticalCookies = true;
    }
  }

  confirmButton(): void {
    if (this.analyticalCookies) {
      this.dialogRef.close(AnalyticalCookiesConsent.ACCEPTED);
    } else {
      this.dialogRef.close(AnalyticalCookiesConsent.REJECTED);
    }
  }

  cancelButton(): void {
    this.dialogRef.close(null);
  }
}
