import { datadogRum } from '@datadog/browser-rum';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class I18nMissingTranslationHandler extends MissingTranslationHandler {
  private readonly reportedMissingTranslations: Set<string> = new Set<string>();

  handle({ key }: MissingTranslationHandlerParams): string {
    if (!this.reportedMissingTranslations.has(key) && !key.includes(' ')) {
      this.reportedMissingTranslations.add(key);
      datadogRum.addError('Missing translation', { key });
    }
    return key;
  }
}
