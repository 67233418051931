import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class UIDialogService {
  constructor(private readonly dialog: MatDialog) {}

  open<T>(component: ComponentType<T>): void {
    this.dialog.open(component, { maxWidth: 'calc(100vw - 2rem)' });
  }
}
