import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Environment, EnvironmentService } from '@util/environment';
import { BrandingColorMap } from './branding-color-map.interface';
import { BRANDING_COLOR_MAPS } from './brands.constants';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  constructor(
    private readonly environment: EnvironmentService,
    private readonly title: Title,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  initBranding(): void {
    this.document.body.classList.add('ui-branding--' + this.environment.environment.branding);
    this.title.setTitle(this.environment.environment.pageTitle);
  }

  brand(): Environment['branding'] {
    return this.environment.environment.branding;
  }

  brandColors(): BrandingColorMap {
    if (!BRANDING_COLOR_MAPS[this.brand()]) {
      throw new Error(`Brand color map not found for brand: ${this.brand()}`);
    }
    return BRANDING_COLOR_MAPS[this.brand()];
  }
}
