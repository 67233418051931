import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
  ApolloClientOptions,
  ApolloError,
  ApolloLink,
  DefaultOptions,
  FetchResult,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
} from '@apollo/client/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { EnvironmentService } from '@util/environment';
import { HttpBatchLink } from 'apollo-angular/http';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';
import { ErrorParam, Error as GqlErrorResponse } from './generated/gql.model';
import { GraphqlService } from './graphql.service';

const ERROR_TYPENAME: GqlErrorResponse['__typename'] = 'Error';

export function provideApollo(
  httpLink: HttpBatchLink,
  graphqlService: GraphqlService,
  env: EnvironmentService,
): ApolloClientOptions<NormalizedCacheObject> {
  const uri: string = `${env.environment.api.graphqlGateway}/graphql`;
  const middleware: ApolloLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    // Apollo uses zen Observables instead of rxjs Observables. (https://github.com/zenparsing/zen-observable/)
    // Conceptually they are the same, but they have different mappings which will break the code.
    // Therefor we need to map the rxjs Observable to a zen Observable
    return new Observable<string>((observer) =>
      graphqlService.token$().subscribe({
        next: (s: string) => observer.next(s),
        error: (err: unknown) => observer.error(err),
        complete: () => observer.complete(),
      }),
    ).flatMap((token: string) => {
      const useMultipart: boolean = !!operation.getContext()['useMultipart'];
      const authorizationHeader: string = `Bearer ${token || null}`;

      // Using an instance of HttpHeaders when using the Apollo uploadLink does not work
      if (!useMultipart) {
        operation.setContext({
          headers: new HttpHeaders().set('Authorization', authorizationHeader),
        });
      } else {
        operation.setContext({
          headers: { Authorization: authorizationHeader },
        });
      }
      return forward(operation);
    });
  });
  const cache: InMemoryCache = new InMemoryCache();
  const defaultOptions: DefaultOptions = { query: { fetchPolicy: 'no-cache' } };
  const graphqlHttpLink: ApolloLink = httpLink.create({ uri });
  const uploadLink: ApolloLink = createUploadLink({ uri });
  const apolloLink: ApolloLink = ApolloLink.split(
    (operation: Operation) => operation.getContext()['useMultipart'],
    middleware.concat(uploadLink),
    middleware.concat(graphqlHttpLink),
  );

  const networkErrorLink: ApolloLink = onError((error: ErrorResponse) => {
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach((gqlError: GraphQLError) => {
        if (graphqlService.shouldLogout(gqlError)) {
          graphqlService.logout();
        }
        throw new ApolloError({ errorMessage: gqlError.message });
      });
    }
    if (error.networkError && error.networkError instanceof HttpErrorResponse) {
      const message: string = error.networkError.error?.message;
      throw new ApolloError({ errorMessage: message });
    }
  });

  // Custom response handling as a link: https://www.apollographql.com/docs/react/api/link/introduction/#handling-a-response
  const schemaErrorLink: ApolloLink = new ApolloLink((operation: Operation, forward: NextLink) =>
    forward(operation).map((data: FetchResult<any>) => {
      if (data.errors?.length > 0) {
        data.errors.forEach((error: GraphQLError) => {
          const message: string = error.message;
          throw new ApolloError({ errorMessage: message });
        });
      }

      // TODO: Do we still want to keep this, since BE should always return GraphQLError?
      Object.keys(data.data)
        .filter((key: string) => data.data[key]?.__typename === ERROR_TYPENAME)
        .forEach((key: string) => {
          const message: string = data.data[key].message;
          const params: object = data.data[key].params ? mapErrorParamArrayToObject(data.data[key].params) : {};
          throw new ApolloError({ errorMessage: message, extraInfo: { params } });
        });
      return data;
    }),
  );

  const link: ApolloLink = ApolloLink.from([networkErrorLink, schemaErrorLink, apolloLink]);
  return { link, cache, defaultOptions };
}

export function mapErrorParamArrayToObject(params: ErrorParam[]): object {
  return params.reduce(
    (paramObject: object, param: ErrorParam) => ({ ...paramObject, [param.param]: param.value }),
    {},
  );
}
