import { Injectable } from '@angular/core';
import { EnvironmentService } from '@util/environment';
import { filter } from 'rxjs';
import { AnalyticalCookiesConsent } from '../cookies/analytical-cookies-consent.enum';
import { CookiesService } from '../cookies/cookies.service';
import { setupHeap } from './setup-heap';

declare global {
  interface Window {
    heap: {
      identify: (userId: string) => void;
      resetIdentity: () => void;
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class HeapService {
  identified: boolean = false;
  initialised: boolean = false;

  constructor(
    private readonly env: EnvironmentService,
    private readonly cookiesService: CookiesService,
  ) {}

  init(): void {
    if (!this.initialised && this.enableHeap()) {
      this.cookiesService.analyticalCookiesConsent$
        .pipe(filter((consent: AnalyticalCookiesConsent) => consent === AnalyticalCookiesConsent.ACCEPTED))
        .subscribe(() => {
          this.initialised = true;
          setupHeap(this.env.environment.heapIdentifier);
        });
    }
  }

  identify(userId: string): void {
    if (window.heap && !this.identified) {
      window.heap.identify(userId);
      this.identified = true;
    }
  }
  resetIdentify(): void {
    if (window.heap) {
      this.identified = false;
      window.heap.resetIdentity();
    }
  }

  // Only enable heap when there is a heapIdentifier and the heapOrigin matches
  private enableHeap(): boolean {
    if (!this.env.environment.heapIdentifier) {
      return false;
    }
    if (!this.env.environment.heapOrigins || !this.env.environment.heapOrigins.includes(window.location.origin)) {
      return false;
    }
    return true;
  }
}
