import { forwardRef, Provider, Type } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export function uiValueAccessorProvider(x: Type<ControlValueAccessor>): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => x),
    multi: true,
  };
}
