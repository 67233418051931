import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrandingModule } from '@feature/branding';
import { UIPageSpinnerComponent } from './page-spinner.component';
import { UISpinnerComponent } from './spinner.component';

@NgModule({
  declarations: [UISpinnerComponent, UIPageSpinnerComponent],
  imports: [CommonModule, MatProgressSpinnerModule, BrandingModule],
  exports: [UISpinnerComponent, UIPageSpinnerComponent],
})
export class UISpinnerModule {}
