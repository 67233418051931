import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'button[ui-button], button[ui-icon-button], button[ui-fab-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIButtonComponent {
  @Input() color: string | undefined;

  @HostBinding('attr.color')
  get colorAttribute() {
    return this.color;
  }
}
