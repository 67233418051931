/* eslint-disable @typescript-eslint/naming-convention */

import { BrandingColorMap } from '../../branding-color-map.interface';

export const BRANDING_COLOR_MAP_ASPIRE: BrandingColorMap = {
  'primary-100': '#eeeff7',
  'primary-200': '#cbcfe7',
  'primary-400': '#525eb7',
  'primary-600': '#282d4f',
  'primary-800': '#1c203a',
};
