import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIIconModule } from '../icon';
import { UIBottomNavigationItemComponent } from './components/bottom-navigation-item/bottom-navigation-item.component';
import { UIBottomNavigationComponent } from './components/bottom-navigation/bottom-navigation.component';

@NgModule({
  declarations: [UIBottomNavigationComponent, UIBottomNavigationItemComponent],
  imports: [CommonModule, UIIconModule],
  exports: [UIBottomNavigationComponent, UIBottomNavigationItemComponent],
})
export class UIBottomNavigationModule {}
