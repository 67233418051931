/* eslint-disable @typescript-eslint/naming-convention */

import { BrandingColorMap } from '../../branding-color-map.interface';

export const BRANDING_COLOR_MAP_MTIBA: BrandingColorMap = {
  'primary-100': '#f1f7ed',
  'primary-200': '#bbd6a3',
  'primary-400': '#77ad47',
  'primary-600': '#528029',
  'primary-800': '#152507',
};
