<form class="row" [formGroup]="phoneNumberForm" (ngSubmit)="onSubmit()">
  <ng-content select="label"></ng-content>
  <ui-form-field class="col-4">
    <select
      [formControl]="countryCode"
      [class.error]="phoneNumberForm.touched && phoneNumberForm.hasError(INVALID_PHONE_ERROR)"
    >
      <option [value]="null" selected disabled hidden>Select</option>
      <option *ngFor="let countryCode of countryCodes" [value]="countryCode.code">
        {{ countryCode.code }}
      </option>
    </select>
  </ui-form-field>
  <ui-form-field class="col-8">
    <input
      [formControl]="nationalNumber"
      type="tel"
      autofocus
      [class.error]="phoneNumberForm.touched && phoneNumberForm.hasError(INVALID_PHONE_ERROR)"
      [attr.placeholder]="'countries.' + countryISOCode + '.placeholder' | translate"
    />
  </ui-form-field>

  <ui-form-field-error
    *ngIf="
      (countryCode.touched && countryCode.hasError('required')) ||
      (nationalNumber.touched && nationalNumber.hasError('required'))
    "
  >
    Phone number cannot be empty
  </ui-form-field-error>

  <ui-form-field-error *ngIf="phoneNumberForm.touched && phoneNumberForm.hasError(INVALID_PHONE_ERROR)">
    Phone number is not valid
  </ui-form-field-error>
</form>
