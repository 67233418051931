import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ErrorModule } from '@data-access/api/error';
import { GraphqlModule } from '@data-access/graphql';
import {
  AnalyticsModule,
  CookiesService,
  ErrorTrackingService,
  GlobalErrorHandler,
  HeapService,
} from '@feature/analytics';
import { AuthModule, AuthService } from '@feature/auth';
import { MemberService } from '@feature/member';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { UISnackbarModule } from '@ui/components';
import { EnvironmentService } from '@util/environment';
import { I18nRootModule } from '@util/i18n';
import { NgxMaskModule } from 'ngx-mask';
import { filter, Observable, switchMap, take, tap } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const APP_INITIALIZER_FACTORY: (
  memberService: MemberService,
  environmentService: EnvironmentService,
  heapService: HeapService,
  cookiesService: CookiesService,
  errorTrackingService: ErrorTrackingService,
) => () => Observable<boolean> =
  (
    memberService: MemberService,
    environmentService: EnvironmentService,
    heapService: HeapService,
    cookiesService: CookiesService,
    errorTrackingService: ErrorTrackingService,
  ) =>
  () =>
    environmentService.loadEnvironment$().pipe(
      switchMap(() => environmentService.loadInfo$()),
      tap(() => {
        errorTrackingService.init();
        cookiesService.init();
        heapService.init();
      }),
      switchMap(() => {
        memberService.observeLoggedIn();
        return memberService.initialised$.pipe(
          filter((initialised: boolean) => !!initialised),
          take(1),
        );
      }),
    );

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      rippleEffect: false,
      animated: true,
    }),
    AppRoutingModule,
    MatSnackBarModule,
    UISnackbarModule,
    AuthModule,
    GraphqlModule,
    ErrorModule,
    NgxMaskModule.forRoot(),
    I18nRootModule,
    AnalyticsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        verticalPosition: 'bottom',
      },
    },
    { provide: AuthService },
    {
      provide: APP_INITIALIZER,
      useFactory: APP_INITIALIZER_FACTORY,
      deps: [MemberService, EnvironmentService, HeapService, CookiesService, ErrorTrackingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
