<div class="dialog">
  <div mat-dialog-content>
    <h3 class="dialog__title">
      <ng-content select="[title]"></ng-content>
    </h3>
    <div class="mb-4 mt-3 dialog__description">
      <ng-content select="[description]"></ng-content>
    </div>
    <div class="d-flex">
      <div class="mt-2 me-4">
        <ng-content select="[primary-action]"></ng-content>
      </div>
      <div class="ml-4 mt-2">
        <ng-content select="[secondary-action]"></ng-content>
      </div>
    </div>
  </div>
</div>
