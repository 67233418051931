<div class="stepper mt-4 mb-4 d-flex me-4 ms-4" *ngIf="steps">
  <ng-container *ngFor="let step of steps; index as stepIndex">
    <div
      class="step d-flex justify-content-center align-items-center"
      [class.step--done]="currentStep > stepIndex"
      [class.step--active]="currentStep === stepIndex"
    >
      <ng-container *ngIf="currentStep <= stepIndex">{{ stepIndex + 1 }}</ng-container>
      <ui-icon icon="check-single" color="pure-white" *ngIf="currentStep > stepIndex"></ui-icon>
    </div>
    <div class="step-line flex-fill" *ngIf="stepIndex !== steps.length - 1"></div>
  </ng-container>
</div>

<ng-container *ngIf="steps">
  <div *ngFor="let item of steps; index as index">
    <ng-container [ngTemplateOutlet]="item.content" *ngIf="index === currentStep"></ng-container>
  </div>
</ng-container>
