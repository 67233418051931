import { Injectable } from '@angular/core';
import { UserOperationMember } from '@data-access/api/user-operation';
import { map, Observable, take } from 'rxjs';
import { MemberService } from '../services/member/member.service';
// eslint-disable-next-line no-restricted-imports
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MemberGuard {
  constructor(
    private readonly memberService: MemberService,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.memberService.member$.pipe(
      take(1),
      map((member: UserOperationMember | null) => {
        if (member) {
          return true;
        }
        return this.router.createUrlTree(['/tabs/home/offline']);
      }),
    );
  }
}
