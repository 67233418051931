import { HttpErrorResponse } from '@angular/common/http';

// for checking if we are dealing with a specific Error subclass,
// you'd normally use the instanceof operator. However,
// `error instanceof ApiError` does not work due to the following TypeScript limitation:
// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
// therefore, as a workaround, we use the `name` property of the Error class to
// check that we are dealing with a ApiError until the above issue is fixed.
const API_ERROR_NAME = 'ApiError';

export class ApiError extends Error {
  // originalError is there so that when ApiError is custom-handled in a component,
  // the original error (incl. stack trace etc.) is there for any error message extraction needed.
  originalError: HttpErrorResponse;

  static isInstance(error: any | ApiError): boolean {
    return error instanceof Error && error.name === API_ERROR_NAME;
  }

  constructor(message: string, originalError: any = null) {
    super(message);
    this.name = API_ERROR_NAME;
    this.originalError = originalError;
  }
}
