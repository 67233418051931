import { ApolloError } from '@apollo/client';
import { UNKNOWN_ERROR_TRANSLATION_KEY } from '@data-access/api/error';

export function isApolloNetworkError(error: ApolloError): boolean {
  if (`${error}` === `ApolloError: ${UNKNOWN_ERROR_TRANSLATION_KEY}`) {
    return true;
  } else {
    return false;
  }
}
