import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { enUS } from 'date-fns/locale';
import { DATE_FORMATS } from './date/constants/date-formats.constant';

@NgModule({
  imports: [CommonModule, MatDateFnsModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
  ],
})
export class UIConfigurationModule {}
