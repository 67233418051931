import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UIIcon } from '../icon/icon.enum';

import { UISnackbarType } from './snackbar-type.enum';
import { SnackbarMap, SnackbarViewModel } from './snackbar-view-model.type';
import { UISnackbar } from './snackbar.interface';

@Component({
  selector: 'ui-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);
  snackbarViewModel: SnackbarMap = {
    [UISnackbarType.Positive]: { icon: UIIcon.CheckSingle, color: 'green' },
    [UISnackbarType.Negative]: { icon: UIIcon.CircleWarning, color: 'red', actionText: 'DISMISS' },
    [UISnackbarType.Warning]: { icon: UIIcon.Warning, color: 'orange', actionText: 'OK' },
    [UISnackbarType.Neutral]: {},
  };

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: UISnackbar) {}

  get snackbar(): SnackbarViewModel {
    return this.snackbarViewModel[this.data.type];
  }
}
