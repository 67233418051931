import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UITabsComponent } from './components/tabs/tabs.component';

@NgModule({
  declarations: [UITabsComponent],
  imports: [CommonModule],
  exports: [UITabsComponent],
})
export class UITabsModule {}
