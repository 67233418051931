import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIIconModule } from '../icon';
import { UIListButtonComponent } from './components/list-button/list-button.component';
import { UIListContentComponent } from './components/list-content/list-content.component';
import { UIListDividerLineComponent } from './components/list-divider-line/list-divider-line.component';
import { UIListExpansionComponent } from './components/list-expansion/list-expansion.component';
import { UIListHeaderComponent } from './components/list-header/list-header.component';
import { UIListItemComponent } from './components/list-item/list-item.component';

@NgModule({
  declarations: [
    UIListHeaderComponent,
    UIListItemComponent,
    UIListDividerLineComponent,
    UIListButtonComponent,
    UIListExpansionComponent,
    UIListContentComponent,
  ],
  imports: [CommonModule, UIIconModule],
  exports: [
    UIListHeaderComponent,
    UIListItemComponent,
    UIListDividerLineComponent,
    UIListButtonComponent,
    UIListExpansionComponent,
    UIListContentComponent,
  ],
})
export class UIListModule {}
