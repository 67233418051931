import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIFormFieldModule } from '../form-field/form-field.module';
import { UIPhoneNumberComponent } from './components/phone-number.component';

@NgModule({
  declarations: [UIPhoneNumberComponent],
  exports: [UIPhoneNumberComponent],
  imports: [CommonModule, ReactiveFormsModule, UIFormFieldModule, TranslateModule.forChild()],
})
export class UIPhoneNumberModule {}
