import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UIButtonModule, UIDialogModule, UIToggleModule } from '@ui/components';
import { CookiesDialogComponent } from './cookies/components/cookies-dialog/cookies-dialog.component';
import { CookiesOverlayComponent } from './cookies/components/cookies-overlay/cookies-overlay.component';

@NgModule({
  declarations: [CookiesDialogComponent, CookiesOverlayComponent],
  imports: [CommonModule, OverlayModule, UIButtonModule, MatDialogModule, UIDialogModule, FormsModule, UIToggleModule],
  exports: [CookiesOverlayComponent],
})
export class AnalyticsModule {}
