<div
  class="list-item"
  [class.list-item--with-prefix]="prefix.children.length"
  [class.list-item--with-status]="status.children.length"
>
  <div class="list-item__prefix" #prefix>
    <ng-content select="[ui-prefix]"></ng-content>
  </div>
  <div class="list-item__content">
    <div class="list-item-title"><ng-content select="[ui-list-item-title]"></ng-content></div>
    <div class="list-item-label"><ng-content select="[ui-list-item-label]"></ng-content></div>
    <div class="list-item-gap" *ngIf="subtitle.children.length || value.children.length"></div>
    <div class="list-item-subtitle" #subtitle><ng-content select="[ui-list-item-subtitle]"></ng-content></div>
    <div class="list-item-value" #value><ng-content select="[ui-list-item-value]"></ng-content></div>
    <div class="list-item-status" #status><ng-content select="[ui-list-item-status]"></ng-content></div>
  </div>
  <div class="list-item__suffix">
    <div class="suffix-margin">
      <ng-content select="[ui-suffix]"></ng-content>
    </div>
  </div>
  <div class="list-item__chevron-right">
    <ui-icon icon="chevron-right" color="granite"></ui-icon>
  </div>
  <div class="list-item__chevron-down">
    <ui-icon icon="chevron-down" color="granite"></ui-icon>
  </div>
</div>
