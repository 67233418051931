import { HttpBackend } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { I18nLazyLoadConfiguration } from './interfaces';

export function i18nLoaderFactory(
  http: HttpBackend,
  lazyLoadedTranslations: I18nLazyLoadConfiguration[] = [],
): TranslateLoader {
  const extraTranslations: ITranslationResource[] = lazyLoadedTranslations.map((config: I18nLazyLoadConfiguration) => ({
    prefix: `./assets/i18n/${config.filePath}/${config.fileName}.`,
    suffix: `-i18n.json?v=${new Date().getTime()}`,
  }));

  const translationFiles: ITranslationResource[] = [
    { prefix: './assets/i18n/', suffix: `.json?v=${new Date().getTime()}` },
    ...extraTranslations,
  ];

  return new MultiTranslateHttpLoader(http, translationFiles);
}
