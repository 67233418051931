import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIPoweredByCarepayComponent } from './components/powered-by-carepay/powered-by-carepay.component';

@NgModule({
  declarations: [UIPoweredByCarepayComponent],
  imports: [CommonModule],
  exports: [UIPoweredByCarepayComponent],
})
export class UIPoweredByCarepayModule {}
