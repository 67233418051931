import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentService } from '@util/environment';
import { uiCurrencySymbol } from './currency-symbol';
import { UIMonetaryAmount } from './monetary-amount.interface';

interface UIFormatCurrencyOptions {
  displayCurrency?: boolean;
  hideAmount?: boolean;
  hideDecimals?: boolean;
}

export function uiFormatCurrency(
  locale: string,
  amount?: UIMonetaryAmount,
  options: UIFormatCurrencyOptions = {},
): string {
  if (!amount || amount.amount == null) {
    return '';
  }
  if (options?.hideAmount) {
    return uiCurrencySymbol(amount.currency);
  }

  return formatCurrency(
    amount.amount,
    locale,
    options.displayCurrency === false ? '' : uiCurrencySymbol(amount.currency) + ' ',
    amount.currency,
    options.hideDecimals ? '1.0-0' : undefined,
  );
}

@Pipe({ name: 'uiCurrency' })
export class UICurrencyPipe implements PipeTransform {
  constructor(private readonly env: EnvironmentService) {}
  transform(amount?: UIMonetaryAmount, options: UIFormatCurrencyOptions = {}): string {
    return uiFormatCurrency(this.env.environment.locale, amount, options);
  }
}
