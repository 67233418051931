import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIButtonModule } from '../button/button.module';
import { UIFormFieldModule } from '../form-field/form-field.module';
import { UIIconModule } from '../icon/icon.module';
import { UIFileItemComponent } from './components/file-item/file-item.component';
import { UISelectFilesComponent } from './components/select-files/select-files.component';
import { UIFileSizePipe } from './pipes/file-size/file-size.pipe';

@NgModule({
  declarations: [UISelectFilesComponent, UIFileItemComponent, UIFileSizePipe],
  exports: [UISelectFilesComponent],
  imports: [CommonModule, UIIconModule, UIFormFieldModule, ReactiveFormsModule, UIButtonModule],
})
export class UISelectFilesModule {}
