import { Injectable } from '@angular/core';
import {
  UserOperationBankAccountDetails,
  UserOperationDataService,
  UserOperationEmailOtpVerification,
  UserOperationMember,
  UserOperationMemberKyc,
  UserOperationUpdatePhoneNumberRequest,
} from '@data-access/api/user-operation';
import { AuthService, CpUserResponse } from '@feature/auth';
import { FreshdeskService } from '@feature/freshdesk';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private readonly memberSubject$: BehaviorSubject<UserOperationMember | null> =
    new BehaviorSubject<UserOperationMember | null>(null);
  readonly member$: Observable<UserOperationMember | null> = this.memberSubject$.asObservable();

  private readonly initialisedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly initialised$: Observable<boolean> = this.initialisedSubject$.asObservable();

  readonly memberId$: Observable<string> = this.member$.pipe(
    filter((member: UserOperationMember | null) => !!member),
    map((member: UserOperationMember | null) => member?.id + ''),
  );

  constructor(
    private readonly userOperationDataService: UserOperationDataService,
    private readonly authService: AuthService,
    private readonly freshdeskService: FreshdeskService,
  ) {}

  observeLoggedIn(): void {
    this.authService.isLoggedIn$
      .pipe(
        distinctUntilChanged(),
        switchMap((loggedIn: boolean) => {
          if (loggedIn) {
            return this.authService.user$.pipe(take(1));
          } else {
            return of(null);
          }
        }),
        switchMap((user: CpUserResponse | null) => {
          if (user) {
            return this.retrieveMember$(user.userDetails.username).pipe(
              catchError(() => {
                return of(null);
              }),
            );
          }
          return of(null);
        }),
        tap((member: null | UserOperationMember) => {
          this.initialisedSubject$.next(true);
          if (!member) {
            this.removeMember();
          }
        }),
      )
      .subscribe();
  }

  retryLogin$(): Observable<UserOperationMember> {
    return this.authService.user$.pipe(
      take(1),
      switchMap((user: CpUserResponse | null) =>
        this.retrieveMember$(user.userDetails.username).pipe(
          catchError(() => {
            return EMPTY;
          }),
        ),
      ),
    );
  }

  addBankAccount$(bankAccount: UserOperationBankAccountDetails): Observable<UserOperationBankAccountDetails> {
    const member: UserOperationMember = this.memberSubject$.getValue() as UserOperationMember;
    return this.userOperationDataService
      .addBankAccountDetails$(bankAccount, member.id)
      .pipe(
        tap((bankAccount: UserOperationBankAccountDetails) =>
          this.updateMember({ ...member, bankAccount: bankAccount }),
        ),
      );
  }

  updateBankAccount$(bankAccount: UserOperationBankAccountDetails): Observable<UserOperationBankAccountDetails> {
    const member: UserOperationMember = this.memberSubject$.getValue() as UserOperationMember;
    return this.userOperationDataService
      .updateBankAccountDetails$(bankAccount, member.id)
      .pipe(
        tap((bankAccount: UserOperationBankAccountDetails) =>
          this.updateMember({ ...member, bankAccount: bankAccount }),
        ),
      );
  }

  deleteBankAccount$(): Observable<void> {
    const member: UserOperationMember = this.memberSubject$.getValue() as UserOperationMember;
    return this.userOperationDataService
      .deleteBankAccountDetails$(member.id)
      .pipe(tap(() => this.updateMember({ ...member, bankAccount: undefined })));
  }

  updateDetails$(kyc: Partial<UserOperationMemberKyc>): Observable<UserOperationMember> {
    const member: UserOperationMember = this.memberSubject$.getValue() as UserOperationMember;
    return this.userOperationDataService
      .updateMember$(member.id, {
        ...member,
        kyc: { ...member.kyc, ...kyc },
      })
      .pipe(tap((member: UserOperationMember) => this.updateMember(member)));
  }

  updatePhoneNumber$(request: UserOperationUpdatePhoneNumberRequest): Observable<void> {
    const member: UserOperationMember = this.memberSubject$.getValue();
    return this.userOperationDataService.updatePhoneNumber$(member.id, request).pipe(
      tap(() =>
        this.updateMember({
          ...member,
          kyc: { ...member.kyc, phoneNumber: request.newPhoneNumber },
        }),
      ),
    );
  }

  updateEmailAddress$(request: UserOperationEmailOtpVerification): Observable<void> {
    const member: UserOperationMember = this.memberSubject$.getValue();
    return this.userOperationDataService.updateEmailAddress$(member.id, request).pipe(
      tap(() =>
        this.updateMember({
          ...member,
          kyc: { ...member.kyc, email: request.email },
        }),
      ),
    );
  }

  private retrieveMember$(username: string): Observable<UserOperationMember> {
    return this.userOperationDataService.getMember$(username).pipe(
      map((member: UserOperationMember) => {
        this.memberSubject$.next(member);
        this.freshdeskService.setMember(member);
        return member;
      }),
    );
  }

  private updateMember(member: UserOperationMember): void {
    this.memberSubject$.next(member);
    this.freshdeskService.setMember(member);
  }

  private removeMember(): void {
    this.memberSubject$.next(null);
    this.freshdeskService.setMember(null);
  }
}
