import { Component } from '@angular/core';
import { UIControlValueAccessorBase, uiValueAccessorProvider } from '../../../form';

@Component({
  selector: 'ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [uiValueAccessorProvider(UICheckboxComponent)],
})
export class UICheckboxComponent extends UIControlValueAccessorBase<boolean> {
  checked: boolean = false;
  disabled: boolean = false;

  constructor() {
    super();
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  private onModelChange(value: boolean): void {
    if (this.disabled) {
      return;
    }

    this.checked = value;

    if (this.onChangeFn) {
      this.onChangeFn(value);
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  buttonClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.onModelChange(!this.checked);
  }
}
