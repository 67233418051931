import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-file-item',
  styleUrls: ['./file-item.component.scss'],
  templateUrl: './file-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIFileItemComponent {
  @Input() file!: File;
  @Input() tooLarge: boolean = false;
  @Output() readonly clickEvent: EventEmitter<void> = new EventEmitter<void>();

  click(): void {
    this.clickEvent.emit();
  }
}
