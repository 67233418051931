import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MIME_TYPE_PROBLEM_JSON } from '../constants/mime-type-problem-json.constant';
import { UNKNOWN_ERROR_TRANSLATION_KEY } from '../constants/unknown-error-translation-key.constant';
import { CpProblemJson } from '../interfaces/problem-json';

@Injectable({
  providedIn: 'root',
})
export class ErrorFormattingService {
  formatApiError(error: HttpErrorResponse): string {
    if (error.headers && error.headers.get('content-type') === MIME_TYPE_PROBLEM_JSON) {
      return this.handleProblemJson(error.error);
    }
    return UNKNOWN_ERROR_TRANSLATION_KEY;
  }

  private handleProblemJson(problemJson: CpProblemJson): string {
    return problemJson.message || problemJson.detail;
  }
}
