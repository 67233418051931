import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIToggleComponent } from './components/toggle/toggle.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  declarations: [UIToggleComponent],
  exports: [UIToggleComponent],
})
export class UIToggleModule {}
