<ui-dialog>
  <ng-container title>Manage cookies</ng-container>
  <ng-container description>
    <p class="mb-45">
      Please note that if you choose to remove or reject cookies this could affect the features, availability, and
      functionality of this web site.
    </p>
    <div class="d-flex justify-content-between align-items-center">
      <h3>Necessary Cookies</h3>

      <ui-toggle ui-suffix [(ngModel)]="necessaryCookies" [disabled]="true"></ui-toggle>
    </div>
    <p class="cookie-type-description">
      Necessary cookies are essential for you to be able to browse the site and use its features.
    </p>

    <div class="d-flex justify-content-between align-items-center">
      <h3>Analytical Cookies</h3>

      <ui-toggle ui-suffix [(ngModel)]="analyticalCookies"></ui-toggle>
    </div>
    <p class="cookie-type-description">
      Analytical cookies are used to enhance the platform experience. All user data is completely anonymized and they
      are not used for targeting adverts.
    </p>
  </ng-container>
  <button ui-button type="primary" primary-action (click)="confirmButton()">Save settings</button>
  <button ui-button type="secondary" (click)="cancelButton()" secondary-action>Cancel</button>
</ui-dialog>
